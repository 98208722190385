import { useReducer, createContext } from "react"
import { PatientsReducer,initialState } from "../reducres/patientReducers"
export const PatientContext = createContext({
    state: initialState,
    dispatch: () => null
})

export const PatientProvider = ({ children }) => {
    const [state, dispatch] = useReducer(PatientsReducer, initialState)

    return (
        <PatientContext.Provider value={[state, dispatch]}>
            {children}
        </PatientContext.Provider>
    )
}