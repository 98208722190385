
import { FaSearch,FaUserPlus,FaSignOutAlt } from "react-icons/fa";
import Logout from "./logout";
import AddPatient from "./addPation";
import TopSearch from "./topSearch";



const Header = () => {

  return (
    <div className='sticky top-0 z-50 text-white bg-[#46B59B] w-full shadow-lg'>
    <div className='container flex justify-between text-center py-5 mx-auto max-md:px-5  '>
    <h2 className='text-3xl  font-extrabold font-serif'>Clinic</h2>
  
   <TopSearch/>
    <div   className=" my-auto h-full">
    <div className=' flex gap-9 flex-row '>
  
     <AddPatient/>
     <Logout />
    </div>
    </div>
    </div>
  
    </div>
  )
}

export default Header