import React from 'react'
import { FaAngleLeft, FaPhoneAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const HeaderPatientDetails = ({name,phone,id}) => {
    const history = useNavigate();
  return (
    <>
    <div className='sticky top-0 z-50 text-white bg-[#46B59B] w-full shadow-lg'>
    <div className='container flex justify-between text-center py-5 mx-auto max-md:px-5  '>
        <div className=' flex flex-row justify-center gap-5 text-center'>
        <button onClick={()=>{
history(-1)
        }} className=' text-lg h-full my-auto'><FaAngleLeft /></button>
    <h2 className='text-xl   max-md:w-60 w-full  truncate font-extrabold font-serif'>{name}</h2>
    </div>

    <a href={`tel:${phone}`}  className=' text-white flex gap-5 h-full my-auto'>
        <FaPhoneAlt/>
        </a>
    </div>
   
    </div>
    </>
  )
}

export default HeaderPatientDetails