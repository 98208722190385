import { useReducer, createContext } from "react"
import { ReloadReducer, initialState} from "../reducres/reloadReducers"
export const ReloadContext = createContext({
    state: initialState,
    dispatch: () => null
})

export const ReloadProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ReloadReducer, initialState)

    return (
        <ReloadContext.Provider value={[state, dispatch]}>
            {children}
        </ReloadContext.Provider>
    )
}