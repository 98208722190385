import React, { useCallback, useContext, useRef, useState } from 'react'
import { FaSearch,FaAngleLeft, FaSpinner } from 'react-icons/fa'
import { PatientContext } from '../contexts/patientsContext';
import { useNavigate } from 'react-router-dom';
import PatientCard from './patientCard';
import axios from 'axios';
import apiLink from '../urls';
import _ from 'lodash';


const TopSearch = () => {
    const [openSearch,setOpenSearch] = useState(false)
    const [searchlist,setSearchList]=useState([]);
    const [openSearchDesck,setOpenSearchDesck] = useState(false);
    const [state,dispatch] = useContext(PatientContext);
    const [loading,setLoading]= useState(false)

  const history = useNavigate();
  //     function searchPatient(e){
       
       

  //       const searchVal=e.target.value;
  //       if(searchVal.length >= 3){
  //         if(loading) return
  //       const obj = {
  //         action:"SEARCH",
  //         searchVal:searchVal
  //       }
       
  //   try {
  //     setLoading(true)
  //      axios.post(apiLink + "test.php",  obj
  //     ).then(async (res) =>  {
  //       setLoading(false)
  //       setSearchList(res.data.data)
  //     })
  //   } catch (e) {
    


  //   }
  // }




  //       // if(searchVal.length >= 3){
  //       //  setSearchList( state.listPatients.filter(element => (element.name).toLowerCase().includes(searchVal.toLowerCase())));
  //       // console.log(searchlist);
  //       // }else{
  //       //   setSearchList([]);
  //       // }
  //     }



  const searchPatient = useCallback(
    _.debounce(async (searchVal) => {
      if (searchVal.length >= 3) {
        const obj = {
          action: "SEARCH",
          searchVal: searchVal
        };
        
        try {
          setLoading(true);
          const res = await axios.post(apiLink + "test.php", obj);
          setLoading(false);
          setSearchList(res.data.data);
        } catch (e) {
          console.error(e);
        }
      }
    }, 300), // Adjust the delay (in milliseconds) as needed
    []
  );


      const handleInputChange = (e) => {
        const searchVal = e.target.value;
        searchPatient(searchVal);
      };


      function searchDescktop(e){
        const searchVal=e.target.value;
        if(searchVal.length >= 3){
          setOpenSearchDesck(true)
          searchPatient(searchVal);
       
        }else{
          setOpenSearchDesck(false)
          setSearchList([]);
        }
      }

  return (
    <>
 <div   className=' text-black  bg-white  flex flex-row py-3 relative h-9 text-sm rounded-md w-full mx-48 text-center max-md:hidden'>
      <FaSearch className='my-auto mx-5'/>
      <input onChange={(e)=>searchDescktop(e)} type='text' placeholder='search patient' className=' bg-transparent outline-none border-none  w-full' ></input>
    
   { openSearchDesck&&  <div onClick={()=>setOpenSearchDesck(false)} className=' bg-[#00000058] fixed top-[75px] z-10 left-0 right-0 w-full h-full ' ></div>}
   { openSearchDesck&& <div className=' bg-white  max-h-64 overflow-y-auto z-40 rounded-md absolute w-full top-10'>
      <div className='  flex flex-col px-4 py-2 divide-y-2  '> 
      {searchlist&&searchlist.map((patient)=>{
        return <PatientCard name={patient.name} id={patient.id} date={patient.date_add} phone={patient.phone} ispinned={patient.ispinned} />
      })}
       </div>
    </div>}
    
    </div>
   <button onClick={()=>setOpenSearch(!openSearch)} className=' my-auto h-full translate-x-9 hidden max-md:block'>
    <FaSearch />
   </button>
   <div className={`${!openSearch?"-left-[200%]":"left-0"} transition-all duration-700 h-screen w-screen bg-white absolute top-0 right-0 bottom-0 z-50 `}>
    <div className=' flex justify-center gap-4  border-b py-4 px-5'>
        <button onClick={()=>setOpenSearch(false)}>
        <FaAngleLeft className=' text-gray-400 my-auto h-full'/>
        </button>
        <div className=' w-full flex flex-row justify-between text-center'>
    <input onChange={(e)=>handleInputChange(e)} type='text' placeholder='search patient' className=' text-black outline-none w-full '/>
       {loading?<FaSpinner className=' animate-spin h-full my-auto text-gray-400'/>: <FaSearch className=' h-full my-auto text-gray-400'/>}
    </div>
    
    </div>
    <div className=' max-h-screen flex flex-col  overflow-y-auto py-4 container w-full text-left text-black '>
      {searchlist&&searchlist.map((patient)=>{
        return <PatientCard name={patient.name} date={patient.date_add} id={patient.id} phone={patient.phone} ispinned={patient.ispinned} />
      })}
    </div>
   </div>
    </>
  )
}

export default TopSearch