import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { FaEllipsisV, FaNotEqual, FaTrash } from 'react-icons/fa'
import apiLink from '../urls';

const CardNote = ({note,getnotes}) => {
    const [openMore,setOpenMore]= useState(false);
    const moreButton = useRef(null)
    useEffect(() => {
       
        function handleClickOutside(event) {
          if (moreButton.current && !moreButton.current.contains(event.target)) {
           setOpenMore(false)
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      
    }, []);


    async function deleteNote(id){
        const obj={
          id:id,
          action:"DELETE_NOTE"
        }
        try{
            await axios.post(apiLink+"test.php",obj
            ).then((res)=>{
           getnotes()

            })
          }catch(e){
           console.log(e)
          }
    }



  return (
    <div className=' relative flex pr-3 flex-row justify-between w-full '> <div className=' w-full relative overflow-hidden px-4 py-2 flex flex-col gap-2'>
   <h4 className='flex flex-row items-center gap-3'>
  <FaNotEqual className='h-fit my-auto' />
  <div className='max-w-[310px] whitespace-normal overflow-hidden flex-shrink-0'>{note.Note}</div>
</h4>
        <span className=' text-xs text-gray-400'>{note.Date_Note}</span>
      </div>
      <button  ref={moreButton} onClick={()=>setOpenMore(!openMore)}><FaEllipsisV/></button>
      <div className={`${ !openMore?"top-4 scale-x-0 right-0 -z-20 opacity-0":" scale-x-100 right-5 top-11 opacity-100  z-20"}  transition-all absolute shadow-md h-fit w-fit px-3 py-1 rounded-md bg-white `}
      ><button  onClick={()=>deleteNote(note.Id)} className='text-red-500 flex flex-row gap-2'><FaTrash className=' h-fit my-auto'/>{" "}Delete</button>
      </div>
      </div>
  )
}

export default CardNote