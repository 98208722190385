export const PatientsReducer = (state, action) => {
    switch (action.type) {
        case "setReloadPatients":
            return {
                ...state,
                reloadPatients: action.payload
            }
            case "setListPatients":
                return {
                    ...state,
                    listPatients: action.payload
                }
     

        default:
            return state
    }
}



export const initialState = {
     reloadPatients:false,
     listPatients:null
}