import React, { useEffect } from 'react'
import { Routes, Route, topbar } from "react-router-loading";
import PateintDetails from './pages/patientDetails';
import Home from './pages/homePage';
import CategoriePage from './pages/categoriePage';
import Test from './pages/test';
import Login from './pages/loginPage';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import apiLink from './urls';


const App = () => {
  const history = useNavigate()
  const apiKey = process.env.REACT_APP_API_KEY;
  useEffect(()=>{
    const userCach = Cookies.get("usercach");
    if(userCach == undefined || userCach == null){
      history("/login",{replace:true})
    }else{
      const userCachDecode = JSON.parse(userCach);
      const obj={
        action:"LOG_IN",
        userName:userCachDecode.user,
        password:userCachDecode.pass
    }
       axios.post(apiLink+"test.php",obj).then((res)=>{
        if(res.data.success == false){
          history("/login",{replace:true})
        }
      
      })
    }
   

  
  },[])
  return (
    <>
<Routes  >
    <Route path='/'element={<Home/>}/>
    <Route path='/login'element={<Login/>}/>
    <Route path='/:id'  element={<PateintDetails/>}/>
    <Route path='/test'  element={<Test/>}/>
    <Route path='/:id/:categorieId' element={<CategoriePage/>}/>

    
</Routes>

    
    </>
  )
}

export default App