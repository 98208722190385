export const ReloadReducer = (state, action) => {
    switch (action.type) {
        case "setReloadPatients":
            return {
                ...state,
                reloadPatients: action.payload
            }
     

        default:
            return state
    }
}



export const initialState = {
     reloadPatients:false,
}