import React, { useState, Fragment, useRef, useEffect} from 'react'
import HeaderPatientDetails from '../components/headerPatientDetails'
import { FaAngleRight, FaEdit, FaEllipsisV, FaFileAlt, FaFolder, FaIdBadge, FaItunesNote, FaNotEqual, FaNotesMedical, FaSave, FaWindowClose } from 'react-icons/fa'
import { Dialog, Transition } from '@headlessui/react'
import { useLocation, useNavigate } from 'react-router-dom'

import { format } from 'date-fns';
import axios from 'axios'
import apiLink from '../urls'
import Loader from '../components/loader'
import CardNote from '../components/cardNote'
const PatientDetails = () => {
  const [open, setOpen] = useState(false);
  const[openNotes,setOpenNotes] =useState(false);
  const cancelButtonRef = useRef(null)
  const noteRef = useRef(null);
  const location = useLocation();
  const [categories,setCategories]=useState([])
   const [notes,setNotes]= useState([]);

  const history = useNavigate();
  const [loading ,setLoading] = useState(false)
     
      const nameRef = useRef(null)
      const phoneRef = useRef(null)
  
const{name,phone,id} = location.state



const  addNote=async()=>{
setLoading(true);
const currentDate = new Date();

// Format the date as 'dd/MM/yy'
const formattedDate = format(currentDate, 'dd-MM-yy HH:mm:ss');
  const obj = {
    action: "ADD_NOTE",
    idPatient:id,
    note:noteRef.current.value,
    date:formattedDate



  };
  try{
    
  await axios.post(apiLink+"test.php",obj
  ).then((res)=>{
    noteRef.current.value = '';
getNotes();
   
  
  })
}catch(e){
  setLoading(false)
  console.log(e);
}
}


const  getNotes=async()=>{
  setLoading(true)
    const obj = {
      action: "GET_NOTES",
      idPatient:id
    };
    try{  await axios.post(apiLink+"test.php",obj
    ).then((res)=>{
setNotes(res.data.data);
setLoading(false)
    
    })}catch(e){
      setLoading(false)
      console.log(e)

    }
   
  
  
  }

  const  getCats=async()=>{
    
      const obj = {
        action: "GET_CATE",
        id:id
      };
      try{  await axios.post(apiLink+"test.php",obj
      ).then((res)=>{
 
        setCategories(res.data.data)
      
      })}catch(e){
   
        console.log(e)
  
      }
     
    
    
    }
  
    useEffect(()=>{
      getCats();
      getNotes();
    },[])



useEffect(()=>{
  if(open && !nameRef===null){
  nameRef.current.value = name;
  phoneRef.current.value = phone;
  }
  console.log(nameRef.current);
},[nameRef])

const  updatePatient=async(e)=>{

  e.preventDefault();
  setLoading(true)
  const obj = {
    action: "UPDATE_PATIENT",
    id:id,
    name:nameRef.current.value,
    phone:phoneRef.current.value,
  };
  try{
  await axios.post(apiLink+"test.php",obj
  ).then((res)=>{
    setLoading(false)
    console.log(res);
    setOpen(false);
  })
}catch(e){
  setLoading(false)
  console.log(e);
}
}
  
  return (
    <>
    <HeaderPatientDetails name={name} phone={phone} id={id}/>
    <div className="bg-gray-50 ">
    <div className='   mx-auto'>
    <div className='  w-full'>
    <div  className=' flex flex-col container mx-auto max-md:h-full gap-5 h-screen'>
     
     
<div  onClick={()=>setOpen(true)} className='  mt-4 px-6 py-3 bg-white shadow-md rounded-md'>
  <div className=' flex flex-row justify-between w-full'>
  <div className=' flex flex-row  justify-start gap-4'>
    <div className=' my-auto'>
    <FaEdit className=' text-[#46B59B]'/>
    </div>
  <div className=' flex flex-col justify-start'>
   <h2 className=' text-lg font-bold'>Patient Info</h2>
   <h4 className=' text-sm text-gray-500 '>Edit patient information </h4>
  </div>
  </div>
  <div className='my-auto'><FaAngleRight/></div>
  </div>
  <div>

  </div>
</div>



<button  onClick={()=>{
  getNotes();
  setOpenNotes(true)
}} className='  px-6 py-3 bg-white shadow-md rounded-md'>
  <div className=' flex flex-row justify-between w-full'>
  <div className=' flex flex-row  justify-start gap-4'>
    <div className=' my-auto'>
    <FaNotesMedical className=' text-[#46B59B]'/>
    </div>
  <div className=' flex flex-col justify-start'>
   <h2 className=' text-lg font-bold'>Notes</h2>
   <h4 className=' text-sm '>{notes.length} <span className=' text-gray-500'> Notes</span> </h4>
  </div>
  </div>
  <div className='my-auto'><FaAngleRight/></div>
  </div>
  <div>

  </div>
</button>








     
     
      <div className=' flex shadow-md  w-full flex-col gap-5 h-full'>


  <div className=' bg-white   w-full  p-5'>
  <div className=' flex flex-row justify-start gap-4 text-center'>
     
     <FaFileAlt  className=' text-[#46B59B] h-full my-auto' />
     <h2 className=' text-xl'>Categories</h2>
     </div>
  <div className=' flex flex-col h-full gap-3 mt-5'>

{categories&& categories.map((cate)=>{
  return<div className=' bg-gray-200 text-black flex-row justify-between flex w-full  rounded-md py-3 px-4'>
  <div className=' flex flex-col gap-3'>
   <h2 className=' text-xl font-extrabold font-mono'> {cate.category}</h2>
   <span className=' text-gray-800 text-xs'>{cate.count} files for this patient</span>
   </div>
   <button  onClick={()=>history(`${cate.idcategory}`,{state:{
    name:name,
    id,id,
    cate:cate.category
    
   }})} className=' p-3  h-fit rounded-full bg-black text-yellow-500'>
   <FaFolder />
   </button>
</div>
})}





</div>


  </div>
</div>

</div>


</div>
</div>
    </div>






<div onClick={()=>setOpenNotes(false)}  className={` ${openNotes?"opacity-35 z-30" :"opacity-0 -z-10"} transition-all fixed top-0 bottom-0 left-0 right-0 w-screen h-screen bg-black  `}></div>


<div className={`${openNotes?"h-[430px]":"h-0"} fixed bottom-0 transition-all overflow-hidden z-40  w-full bg-white rounded-t-md shadow-md `}>  
<div className='flex flex-col gap-2'>
  <div className='px-4  border-b  border-gray-400 flex flex-row justify-between w-full'>
  <div className='  flex flex-row justify-start text-black gap-3 font-bold text-lg py-2'> {loading? <div className=' h-fit my-auto'><Loader /></div>:<FaNotesMedical className='text-[#46B59B] my-auto'/> }<h2>Notes</h2></div>
 <button onClick={()=>setOpenNotes(false)}><FaWindowClose className=' text-[#46B59B]'/></button>

  </div>
  <div className='max-h-[300px] h-[300px] w-full overflow-y-auto  '>
    {notes&& notes.map((note)=>{
      return <CardNote note={note} getnotes={getNotes}/>
    })}
  </div>
  <div className='w-full  h-full flex flex-row gap-1 px-1'>
    <input ref={noteRef} type=' text' placeholder=' Add Note' className=' border-2 outline-none border-[#46B59B] rounded-md w-full py-2 px-3'></input>
    <button onClick={()=>addNote()} className=' h-full py-4 px-4    bg-[#46B59B] rounded-md text-white'><FaSave/></button>
  </div>
</div>
</div>





















<Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
               <form onSubmit={(e)=>updatePatient(e)}>
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex  sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  bg-[#46b59b5d] sm:mx-0 sm:h-10 sm:w-10">
                      <FaEdit className="h-6 w-6 text-[#46B59B]" aria-hidden="true" />
                    </div>
                    <div className="mt-3   w-full relative overflow-hidden  sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                       Edit Patient Info
                      </Dialog.Title>
                      <div className=' px-9 border-dashed h-1 border-t-2 '></div>
                      <div className="mt-4">
                      <div className="">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-500">
                Patient Name
              </label>
              <div className="mt-2">
                <input
                ref={nameRef}
                defaultValue={name}
                  type="text"
                  name="street-address"
                  id="street-address"
                  autoComplete="street-address"
                  className="block  w-full transition-all rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none focus:bg-blue-200 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
                      </div>

<div className='w-screen '></div>
                      <div className="mt-2 ">
                      <div className=" ">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-500">
                Patient Phone
              </label>
              <div className="mt-2 ">
                <input
                defaultValue={phone}
                ref={phoneRef}
                  type="text"
                  name="street-address"
                  id="street-address"
                  autoComplete="street-address"
                  className="block w-full  transition-all rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 outline-none focus:ring-inset focus:bg-blue-200 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md  bg-[#46B59B] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#46B59B] sm:ml-3 sm:w-auto"
                    
                  >
                    {loading?<Loader/>:"UPDATE"}

                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>


                </form>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  )
}

export default PatientDetails