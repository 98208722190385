import React, { useContext, useEffect, useRef, useState } from 'react'
import { FaEllipsisV ,FaMapPin,FaEdit, FaPhone} from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom';
import { slugifyText } from '../services/Utils';
import apiLink from '../urls';
import { PiPushPinSlashFill } from "react-icons/pi";
import axios from 'axios';
import { TbPinFilled } from "react-icons/tb";
import { PatientContext } from '../contexts/patientsContext';
import { VscPinnedDirty } from "react-icons/vsc";
const PatientCard = ({name,id,phone,ispinned,date}) => {
  
    const [open ,setOpen] = useState(false);
    const moreButton = useRef(null);
    const [state,dispatch] = useContext(PatientContext);
    const history = useNavigate();
    const [loading ,setLoading] = useState(false)
    const  pinPatient=async()=>{

      setLoading(true)
      const obj = {
        action: "PIN_PATIENT",
        id:id,
        ispinned: ispinned==0?1:0
      };
      try{
      await axios.post(apiLink+"test.php",obj
      ).then((res)=>{
        dispatch({type:"setReloadPatients",payload: true})
        setLoading(false)
      })
    }catch(e){
      setLoading(false)
      console.log(e);
    }
    }


  
      useEffect(() => {
       
          function handleClickOutside(event) {
            if (moreButton.current && !moreButton.current.contains(event.target)) {
             setOpen(false)
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        
      }, []);
    
  return (
    <>
    

           <div className={` ${ispinned == 1?"  border-2 border-[#46B59B]":"bg-white"}  px-3 py-3  w-full flex justify-between flex-row text-center relative hover:bg-gray-200 cursor-pointer`}>
      {ispinned==1&& <div className=' absolute right-12 -bottom-2 bg-[#46B59B] z-30 p-1 text-white rounded-full'><VscPinnedDirty/></div>}
      <div onClick={()=>{
      history(`/${id}`,{state:{
        name:name,
        phone:phone,
        id:id
      }})
    }} className=' w-full flex flex-col justify-start text-left gap-2'>
        <h2>{name}</h2>
        <span className=' text-gray-400 text-sm'>{date?date:"2023-02-01"}</span>
      </div>
      <button ref={moreButton} onClick={()=>setOpen(!open)} className={` ${open?"bg-[#46B59B] text-white shadow-lg":"bg-transparent"} h-full px-3 py-3 hover:shadow-lg my-auto hover:bg-[#46B59B] transition-all  rounded-full   relative`}> 
      <div>
        <FaEllipsisV/>
        </div>
        <div className={`${open?"scale-100":"scale-0"} text-black  border-2     flex divide-y-2 flex-col  absolute  z-40 top-9  transition-all  right-3  bg-white  w-32  rounded-md shadow-md`}>
         <button onClick={()=>pinPatient()} className='   flex flex-row justify-between text-center py-2 px-5 gap-2'>{ispinned==0?"Pin":"Remove "}  <span className=' my-auto'>{ispinned==0?<TbPinFilled   className=' text-lg text-[#46B59B]'/>:<PiPushPinSlashFill   className=' text-lg text-[#46B59B]'/>} </span></button>
        {phone &&  <a  href={`tel:${phone}`} className='   flex flex-row justify-between text-center py-2 px-5 gap-2'>Phone <span className=' my-auto'><FaPhone  className=' text-[#46B59B]'/></span></a>}
     
      </div>
      </button>
     
    </div>
   
    </>
  )
}

export default PatientCard