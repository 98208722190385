import React, { useRef, useState } from 'react'

const Test = () => {
    const n1 = useRef(null);
    const n2 = useRef(null);
    const [summ,setSumm] = useState(50);
    const [msg,setmsg] = useState("");

    function checkNumm(){
        const num1 = n1.current.value;
        const num2 = n2.current.value;
        if(Number(num1>0)&& Number(num2)>0){
        if (Number(num1) + Number(num2)== summ){
           setmsg("it ture the sum equal to 50")
        }else{
            setmsg("it false the sum of 2nb ")
        }}else{
            setmsg("one of these nb is negative ")
        }
    }
  return (
    <>
    <div className=' h-screen'>
    <div className=' p-24 bg-slate-400 w-fit mx-auto h-fit m-7 rounded-md my-auto flex gap-4 text-center flex-col'>
    <input ref={n1} className='border ' placeholder='number1'></input>
    <input ref={n2} className=' border' placeholder='number2'></input>
    <button onClick={()=>checkNumm()} className=' w-fit mx-auto bg-blue-600 px-5 py-2 rounded-sm text-gray-100 '>Check</button>
    </div>
    <h2>{msg}</h2>
    </div>
    
    </>
  )
}

export default Test